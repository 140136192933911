import React from 'react'
import Helmet from '../components/helmet'
import HomeHeaderV2 from '../components/home-header-v2'
import Footer from '../components/footer'
import LetterBoxCall from '../components/letterbox-call'

const AboutPage = () => (
    <div>
        <Helmet />
        <HomeHeaderV2 pageTitle="About Us"/>
        <section className="section">
            <div className="container content">
                {/* <h1>About Us</h1> */}
                <p>
                    The Insolvency Helpdesk provides free help and advice for directors and business owners whose companies are suffering financial difficulties. Our advisers are all Licensed Insolvency Practitioners who specialise in rescue, restructure and turnaround of small and medium sized businesses throughout the UK.
    
    With over 30 years’ experience in company insolvency and business recovery, we have the knowledge and most importantly, the understanding, to be able to assist you whatever your business circumstances may be. We will work with you to find the right solution to help you and your business, be it restructure, recovery or simply helping you to walk away and move on.
    </p>
    <h2>Free Advice</h2>
    
    <p>If your Company is in trouble and your supplier bills or debts to HMRC are mounting and you are coming under more and more pressure every day, you need to take action before it is too late rather than just keep on struggling. The earlier you take professional advice, the more options you will have.
    
    At The Insolvency Helpdesk we won’t charge you for initial advice so please don’t leave it too late to call. The more time passes, the more creditor pressure will increase and the more your options will reduce. You may also become personally liable for some of the business debts if you don’t take decisive action.
    
    If, following our initial discussions with you, we are able to help you we will always offer you a meeting with one of our Insolvency Practitioners to ensure that the advice given is exactly right for you and your business.
                </p>
            </div>
        </section>
        <LetterBoxCall />
        <section className="section">
            <div className="container content">
                <h2>Legal Requirements</h2>
                <p>Insolvency advice provided on behalf of The Insolvency Helpdesk is provided by Licensed Insolvency Practitioners from Kay Johnson Gee Corporate Recovery, 
                    Boutique Insolvency and Restructuring Firm of the Year at the 2019 Turnaround, Restructuring and Insolvency Awards.  
                    All of our Insolvency Practitioners are licensed by the Institute of Chartered Accountants in England and Wales.
</p>
<h2>Complaints</h2>
<p>If for any reason you are dissatisfied with the services you are receiving, please contact us. We will carefully consider any complaint we receive and, if we believe that we have given a less than satisfactory service, we will take all reasonable steps to put it right. Whilst we undertake to look into any complaint carefully and promptly and to do all we can to explain the position to you, if you remain unsatisfied, you have the right to refer the matter to the Insolvency Complaints Gateway which is operated by the Insolvency Service, an Executive Agency of the Department for Business, Energy & Industrial Strategy (“BEIS”). Complaints can be submitted via an online complaints form at www.gov.uk/complain-about-insolvency-practitioner (Guidance for those who wish to complain can also be found on this site). If you have difficulty accessing the online complaints form you can also make any complaint through the Insolvency Service Enquiry Line – email insolvency.enquiryline@insolvency.gsi.gov.uk or alternatively telephone 0300 678 0015 (Monday to Friday 9am to 5pm)</p>
                </div>
                </section>
        <Footer />
    </div>
)

export default AboutPage